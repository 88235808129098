import React from "react";
import video from "../assets/video.mp4";
import styled from "styled-components";
import mask from "../assets/mask.svg";
import videoMp4 from "../assets/videos/output.mp4";
import videoWebm from "../assets/videos/output.webm";

const PositioningContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 55vw;
  height: 100vh;
  @media screen and (max-width: 1324px) {
    height: 60vh;
    width: 100%;
  }
  @media screen and (max-width: 842px) {
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;

  @media screen and (max-width: 1324px) {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
  }
  @media screen and (max-width: 773px) {
    display: block;
  }
  video {
    height: 100%;
    right: 0;
    max-width: 100vw;
    object-fit: fill;
    border-left: 1px solid white;
  }
`;

const WhiteRectMask = styled.div`
  display: none;
  position: absolute;
  left: 0px;
  top: 1px;
  width: 16.1%;
  height: 100%;
  background: white;
  @media screen and (max-width: 1625px) {
    display: block;
  }
  @media screen and (max-width: 1324px) {
    width: 60%;
  }
  @media screen and (max-width: 1231px) {
    width: 55%;
  }
  @media screen and (max-width: 1065px) {
    width: 50%;
  }
  @media screen and (max-width: 1000px) {
    width: 40%;
  }
  @media screen and (max-width: 900px) {
    width: 30%;
  }
  @media screen and (max-width: 719px) {
    width: 20%;
  }
  @media screen and (max-width: 613px) {
    width: 10%;
  }
  @media screen and (max-width: 521px) {
    width: 5%;
  }
  @media screen and (max-width: 487px) {
    display: none;
  }
`;

const Mask = styled.img`
  position: absolute;
  top: 0px;
  right: -1px;
  height: 101%;
`;

async function goFullscreen() {
  const player = document.querySelector("video");
  if (player.requestFullscreen) {
    // normal browsers
    try {
      const r = await player.requestFullscreen();
      console.log(r);
    } catch (e) {
      console.error(e);
    }
  } else if (player.webkitSupportsFullscreen) {
    // safari
    try {
      const r = await player.webkitEnterFullScreen();
      console.log(r);
    } catch (e) {
      console.error(e);
    }
  }
}

export default props => {
  if (props.fullscreen) {
    goFullscreen();
  }
  return (
    <PositioningContainer>
      <VideoContainer>
        <WhiteRectMask />
        <Mask src={mask} />
        <video autoPlay loop muted={!props.fullscreen} playsInline>
          <source src={videoMp4} type="video/mp4" />
          <source src={videoWebm} type="video/webm" />
        </video>
      </VideoContainer>
    </PositioningContainer>
  );
};

import React, { Component } from "react";
import Container from "../ui/Container";
import { Grid, GridItem } from "styled-grid-component";
import Title from "../ui/Title";
import Subtitle from "../ui/Subtitle";
import Card from "../elements/Card";
import phone from "../assets/icons/outlined/phone.svg";
import mail from "../assets/icons/outlined/mail.svg";
import styled from "styled-components";
import Padding from "../ui/Padding";
import ContactForm from "../elements/ContactForm";

const ResponsiveGrid = styled(Grid)`
  @media screen and (max-width: 1300px) {
    grid-gap: 40px;
  }
  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const ContactContainer = styled.div`
  @media screen and (max-width: 1150px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default class Contact extends Component {
  state = {
    width: window.innerWidth
  };
  componentDidMount() {
    window.addEventListener("resize", e => {
      this.setState({ width: e.target.innerWidth });
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize");
  }
  render() {
    return (
      <Padding>
        <Container id="contact">
          {/*Ovo je glupo, treba nekako drugacije ali za sad radi*/}
          <div height="100px" width="100px" style={{minHeight: "100px", backgroundColor: "white"}}></div>
          <ResponsiveGrid
            width="100%"
            height="100%"
            templateColumns="1fr 1fr"
            gap="30px"
          >
            <GridItem>
              <Title small>Kontakt</Title>
              <Subtitle style={{ marginBottom: "2.5em" }}>
                Stupite u poslovni kontakt sa nama
              </Subtitle>
              <ResponsiveGrid
                width="100%"
                height="100%"
                templateColumns="1fr 1fr"
              >
                <GridItem>
                  <ContactContainer>
                    <Card
                      iconRight={this.state.width >= 1150}
                      textTitle
                      icon={phone}
                      title="+381 61 2013 074"
                      body="Beograd, Srbija"
                    />
                  </ContactContainer>
                </GridItem>
                <GridItem>
                  <ContactContainer>
                    <Card
                      iconRight={this.state.width >= 1150}
                      textTitle
                      icon={mail}
                      title="office@icd.rs"
                      body="ili samo popunite formu"
                    />
                  </ContactContainer>
                </GridItem>
              </ResponsiveGrid>
            </GridItem>
            <GridItem>
              <ContactForm />
            </GridItem>
          </ResponsiveGrid>
        </Container>
      </Padding>
    );
  }
}

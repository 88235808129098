import React from "react";
import styled, { keyframes } from "styled-components";
import logo from "../assets/logo.svg";
import theme from "../theme";

const fade = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000000;
  background-color: ${theme.color.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 300px;
  max-width: 90%;
  animation: ${fade} 2s linear infinite;
`;

const Loader = () => {
  return (
    <LoaderContainer>
      <Logo src={logo} />
    </LoaderContainer>
  );
};

export default Loader;

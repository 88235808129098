import React, { Component } from "react";
import NavLink from "../ui/NavLink";
import logo from "../assets/logo.svg";
import Flex, { FlexItem } from "styled-flex-component";
import styled from "styled-components";
import { PerspectiveXReverse } from "react-burgers";
import theme from "../theme";
import Title from "../ui/Title";

const NavigationContainer = styled.nav`
  position: fixed;
  height: 90px;
  width: 961px;
  padding-right: 3rem;
  /* -webkit-backdrop-filter: ${({ active }) => (active ? "blur(5px)" : "none")}; */
  background: ${({ active }) => (active ? "white" : "transparent")};
  box-shadow: ${({ active }) => (active ? theme.shadow : "none")};
  opacity: ${({ active }) => (active ? ".9" : "1")};
  transition: all 0.5s ease;
  top: 0;
  z-index: 1000;
  &:hover {
    opacity: 1;
  }
  @media screen and (max-width: 1050px) {
    width: 100vw;

  }
`;

const VisibleOnMobile = styled.div`
  display: none;
  @media screen and (max-width: 1050px) {
    display: block;
  }
`;

const VisibleOnDesktop = styled.div`
  display: block;
  @media screen and (max-width: 1050px) {
    display: none;
  }
`;

const Toggle = styled.div`
  padding: 0 2rem;
  z-index: 1200;
  .Burger {
    transform: scale(0.5) rotate(180deg);
  }
  .BurgerInner {
    width: 25px;
  }
  .BurgerInner,
  .BurgerInner:after,
  .BurgerInner:before {
    background: black;
  }
  .BurgerInner:after,
  .BurgerInner:before {
    width: 40px;
  }
`;

const Sidebar = styled.nav`
  overflow-y: auto;
  background-color: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 999;
  right: 0;
  top: 0;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 2px solid ${theme.color.red};
  transform: ${({ active }) => (active ? "rotateY(0deg)" : "rotateY(90deg)")};
  transform-origin: right;
  transition: all 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-property: transform, background-color;
  transition-duration: 0.15s, 0s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1), cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0s, 0.1s;
  & > ${Flex} {
    height: 50%;
  }
  ${NavLink} {
    padding: 10px 0;
  }
  ${NavLink}:hover {
    border-top: none;
    border-bottom: 2px solid ${theme.color.red};
  }
  @media screen and (orientation: landscape) {
    ${NavLink} {
      font-size: 24px;
    }
  }
`;

class Navigation extends Component {
  state = {
    active: false,
    navigationActive: false
  };
  toggleNav = () => {
    const navigationActive = window.pageYOffset > 25;
    this.setState({ navigationActive });
  };
  componentDidMount() {
    window.addEventListener("scroll", this.toggleNav);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleNav);
  }
  render() {
    const { links } = this.props;
    return (
      <>
        <NavigationContainer active={this.state.navigationActive}>
          <Flex full alignCenter justifyBetween>
            <FlexItem grow={0.5}>
              <img src={logo} alt="IC&D Logo" style={{ marginBottom: "-4px" }} />
            </FlexItem>
            <VisibleOnDesktop as={FlexItem} grow={1}>
              <Flex alignCenter justifyBetween>
                {links.map(({ text, to }) => (
                  <FlexItem key={to}>
                    <NavLink href={to}>{text}</NavLink>
                  </FlexItem>
                ))}
              </Flex>
            </VisibleOnDesktop>
            <VisibleOnMobile>
              <Toggle>
                <PerspectiveXReverse
                  active={this.state.active}
                  onClick={() => this.setState({ active: !this.state.active })}
                />
              </Toggle>
            </VisibleOnMobile>
          </Flex>
        </NavigationContainer>
        <VisibleOnMobile>
          <Sidebar active={this.state.active} onClick={() => this.setState({ active: false })}>
            {/*<Flex column alignCenter justifyBetween>*/}
            {/*  {links.map(({ text, to }) => (*/}
            {/*    <FlexItem key={to}>*/}
            {/*      <Title as={NavLink} href={to} regular capitalize>*/}
            {/*        {text}*/}
            {/*      </Title>*/}
            {/*    </FlexItem>*/}
            {/*  ))}*/}
            {/*</Flex>*/}
          </Sidebar>
        </VisibleOnMobile>
      </>
    );
  }
}

export default Navigation;

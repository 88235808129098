import React, { Component } from "react";
import PropTypes from "prop-types";
import uniq from "lodash.uniq";
import flattendeep from "lodash.flattendeep";
import Container from "../ui/Container";
import Tab from "../ui/Tab";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import PortfolioItem from "../ui/PortfolioItem";

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
`;

const TabsContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  overflow-x: auto;
  width: 100%;
  @media screen and (max-width: 900px) {
    justify-content: flex-start;
  }
  button {
    flex-shrink: 0;
    flex-basis: 175px;
    @media screen and (max-width: 900px) {
      font-size: 12px;
      flex-basis: 145px;
      padding-bottom: 1.5em;
    }
  }
`;

export default class Tabs extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        servicesProvided: PropTypes.arrayOf(PropTypes.string.isRequired)
          .isRequired,
        categories: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
      })
    )
  };

  constructor(props) {
    super(props);
    this.state = {
      activeCategory: "All",
      categories: uniq(
        flattendeep(this.props.items.map(item => item.categories))
      ),
      items: this.props.items,
      width: window.innerWidth
    };
    this.setActiveCategory = this.setActiveCategory.bind(this);
    this.resize = this.resize.bind(this);
  }

  resize(e) {
    this.setState({ width: e.target.innerWidth });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  setActiveCategory(activeCategory) {
    let items = [];

    if (activeCategory === "All") {
      items = this.props.items;
    } else {
      items = this.props.items.filter(item =>
        item.categories.includes(activeCategory)
      );
    }

    this.setState({ activeCategory, items });
  }

  render() {
    return (
      <Icons>
        {this.state.items.map((item, i) => (
          <PortfolioItem item={item} key={i} />
        ))}
      </Icons>
    );
  }
}

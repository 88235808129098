import Rectangle from "./Rectangle";
import styled from "styled-components";

const JumpingRectangle = styled(Rectangle)`
  @media screen and (max-width: 1000px) {
    right: ${({ jumpToRight }) => jumpToRight};
    left: ${({ jumpToLeft }) => jumpToLeft};
    transform: ${({ offset, scale, jumpToRight }) =>
      `translateX(${jumpToRight ? offset : -offset}%) scale(${
        scale ? scale : 1
      })`};
  }
  @media screen and (max-width: 650px) {
    transform: ${({ offset, scale, jumpToLeft }) =>
      `translateX(${jumpToLeft ? offset * 1.5 : -(offset * 1.5)}%) scale(${
        scale ? scale : 1
      })`};
  }
`;

export default JumpingRectangle;

import React from "react";
import Title from "../ui/Title";
import Subtitle from "../ui/Subtitle";
import Container from "../ui/Container";
import solutions from "../data/solutions";
import { Grid, GridItem } from "styled-grid-component";
import Card from "../elements/Card";
import styled from "styled-components";
import Rectangle from "../ui/Rectangle";
import Padding from "../ui/Padding";

const ResponsiveGrid = styled(Grid)`
  @media screen and (max-width: 1300px) {
    grid-gap: 40px;
  }
  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
  }
`;

const Solutions = () => {
  return (
    <section style={{ position: "relative", marginTop: "-75px" }}>
      <Rectangle right="0" top="10%" offset={40} scale=".75" />
      <Padding>
        <Container>
          <div style={{ marginBottom: "125px" }}>
            <Title small>Naša Rešenja</Title>
            <Subtitle>Praktična kreativnost</Subtitle>
          </div>
          <ResponsiveGrid
            width="100%"
            height="100%"
            templateColumns="repeat(2, 1fr)"
            gap="83px"
          >
            {solutions.map(({ icon, title, body }) => (
              <GridItem key={title}>
                <Card icon={icon} title={title} body={body} />
              </GridItem>
            ))}
          </ResponsiveGrid>
        </Container>
      </Padding>
    </section>
  );
};

export default Solutions;

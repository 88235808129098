import React from "react";
import Title from "../ui/Title";
import styled from "styled-components";
import Flex, { FlexItem } from "styled-flex-component";
import Text from "../ui/Text";
import theme from "../theme";

const CardTitle = styled(Title)`
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 2rem;
  color: ${({ dark }) => (dark ? theme.color.white : theme.color.black)};
  @media screen and (max-width: 650px) {
    font-size: 20px;
    line-height: 22px;
  }
`;

const CardText = styled(Text)`
  color: ${({ dark }) => (dark ? theme.color.whiteDark : theme.color.greyDark)};
`;

const Card = ({ icon, title, body, dark, iconRight, textTitle }) => {
  if (iconRight) {
    return (
      <Flex>
        <FlexItem>
          <div style={{ marginRight: "2rem", width: "1.5rem" }}>
            <img src={icon} alt={title} />
          </div>
        </FlexItem>
        <FlexItem>
          <Flex column>
            <FlexItem>
              {textTitle ? (
                <Text>{title}</Text>
              ) : (
                <CardTitle dark={dark}>{title}</CardTitle>
              )}
            </FlexItem>
            <FlexItem>
              <CardText dark={dark}>{body}</CardText>
            </FlexItem>
          </Flex>
        </FlexItem>
      </Flex>
    );
  } else {
    return (
      <Flex column>
        <FlexItem>
          <img src={icon} alt={title} style={{ marginBottom: "2.5rem" }} />
        </FlexItem>
        <FlexItem>
          {textTitle ? (
            <Text>{title}</Text>
          ) : (
            <CardTitle dark={dark}>{title}</CardTitle>
          )}
        </FlexItem>
        <FlexItem>
          <CardText dark={dark}>{body}</CardText>
        </FlexItem>
      </Flex>
    );
  }
};

export default Card;

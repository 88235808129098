import React, { Component } from "react";
import Navigation from "../elements/Navigation";
import Title from "../ui/Title";
import Text from "../ui/Text";
import Button from "../ui/Button";
import Container from "../ui/Container";
import Flex, { FlexItem } from "styled-flex-component";
import JumpingRectangle from "../ui/JumpingRectangle";
import Video from "../elements/Video";
import styled from "styled-components";

const HeaderContent = styled.section`
  position: relative;
  @media screen and (max-width: 1237px) {
    padding-top: 40vh;
  }
  @media screen and (max-width: 320px) {
    padding-top: 50vh;
  }
  @media screen and (orientation: landscape) and (max-width: 800px) {
    padding-top: 60vh;
  }
`;

const navigationLinks = [
  { text: "Home", to: "#" },
  { text: "Usluge", to: "#services" },
  { text: "Naši radovi", to: "#portfolio" },
  { text: "O nama", to: "#about" },
  { text: "Kontakt", to: "#contact" }
];

class Header extends Component {
  state = {
    videoFullscreen: false
  };
  componentDidMount() {
    const player = document.querySelector("video");
    player.addEventListener("canplay", this.props.onVideoReady);
  }

  componentWillUnmount() {
    const player = document.querySelector("video");
    player.removeEventListener("canplay", this.props.onVideoReady);
  }
  render() {
    return (
      <>
        <Video fullscreen={this.state.videoFullscreen} />
        <HeaderContent>
          <Navigation links={navigationLinks} />
          <JumpingRectangle top="40%" jumpToRight="0" offset={-40} />
          <Container screen>
            <Flex full alignCenter wrap="wrap">
              <FlexItem>
                <Title width="523px" style={{ marginBottom: "40px" }}>
                  International Consulting <br />& Development
                </Title>
                <Text width="411px">
                  Isporučujući trud i potpunu posvećenost našim klijentima, u kratkom vremenskom periodu postali smo
                  konkurentan igrač na lokalnom ali i globalnom tržištu konsultantskih usluga, usluga digitalizacije i
                  razvoja softverskih rešenja.
                </Text>
                <Button
                  style={{ marginTop: "45px", zIndex: "999" }}
                  onClick={() => this.setState({ videoFullscreen: true })}
                >
                  Play Full Video
                </Button>
              </FlexItem>
            </Flex>
          </Container>
        </HeaderContent>
      </>
    );
  }
}

export default Header;

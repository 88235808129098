import React from "react";
import Background from "../ui/Background";
import bg from "../assets/background.png";
import Container from "../ui/Container";
import { Grid, GridItem } from "styled-grid-component";
import styled from "styled-components";
import Title from "../ui/Title";
import Subtitle from "../ui/Subtitle";
import Card from "../elements/Card";
import about from "../data/about";
import JumpingRectangle from "../ui/JumpingRectangle";
import dots from "../assets/dots.svg";
import Padding from "../ui/Padding";

const ResponsiveGrid = styled(Grid)`
  @media screen and (max-width: 1300px) {
    grid-gap: 40px;
  }
  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
  }
`;

const Dots = styled.img`
  width: 226px;
  position: absolute;
  top: 60%;
  left: 0;
  transform: translateX(-20%);
  @media screen and (max-width: 650px) {
    display: none;
  }
`;

const Approach = () => {
  return (
    <Background src={bg} style={{ position: "relative" }}>
      <Padding>
        asdasdasdasd
        <JumpingRectangle
          offset={-50}
          top="50%"
          scale="1"
          jumpToRight="-125px"
        />
        <Dots src={dots} alt="Dots" />
        <Container>
          <ResponsiveGrid
            width="100%"
            height="100%"
            templateColumns="1fr 1.5fr"
            gap="83px"
          >
            <GridItem>
              <Title small white>
                Naš Pristup
              </Title>
              <Subtitle white>Mi posao shvatamo ozbiljno</Subtitle>
            </GridItem>
            <GridItem>
              <ResponsiveGrid
                width="100%"
                height="100%"
                templateColumns="1fr 1fr"
                gap="100px"
              >
                {about.map(({ icon, title, body }) => (
                  <GridItem key={title}>
                    <Card
                      icon={icon}
                      title={title}
                      body={body}
                      dark
                      iconRight
                    />
                  </GridItem>
                ))}
              </ResponsiveGrid>
            </GridItem>
          </ResponsiveGrid>
        </Container>
      </Padding>
    </Background>
  );
};

export default Approach;

import styled from "styled-components";
import theme from "../theme";

const Text = styled.p`
  margin: 0;
  color: ${({ white }) =>
    white ? theme.color.whiteDark : theme.color.greyDark};
  font-family: ${theme.font.text};
  font-size: ${theme.fontSize.text};
  line-height: ${theme.lineHeight.text};
  font-weight: normal;
  max-width: ${({ width }) => width};
`;

export default Text;

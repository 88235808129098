import planet from "../assets/icons/full/planet.svg";

export default [
  {
    icon: planet,
    title: "Digital Archive System",
    body:
      "Arhivski sistem za uspostavljanje Digitalne Liste Dokumentarnog materijala i arhivsko upravljanje dokumentima projektovan prema svim zahtevima Zakona o arhivskoj građi i arhivskoj delatnosti.Pored digitalizacije, mikrofilmovanja i arhiviranja po svim arhivističkim principima, služi i za dugotrajno arhiviranje dokumenata koja su izvorno nastala u elektronskoj formi podržavajući sve neophodne formate i alate za integrisanje elektronskog potpisa i elektronskog pečata.\n" +
        "Korisnicima pruža mogućnost vođenja arhivskog poslovanja na zakonom propisan način,jednostavno podešavanje,kreiranje i definisanje nivoa pristupa."
  },
  {
    icon: planet,
    title: "Apis",
    body:
      "Advanced Project Information System za praćenje svih ključnih projektnih parametara na jednom mestu. Praćenje vremena realizacije projekata, troškova i iskorišćenosti svih resursa kao i organizovanja procesa nabavke."
  }
  // {
  //   icon: planet,
  //   title: "Tour Guide",
  //   body:
  //     "Doživite svako turističko i poslovno putovanje na jedinstven i kreativan način. Pronadji lokalnog vodiča i uživaj na bilo kom mestu na planeti."
  // },
  // {
  //   icon: planet,
  //   title: "Zup! App",
  //   body:
  //     "Napravi fotografiju – postavi izazov. Poveži se sa ljudima slicčnih interesovanja sširom sveta i razmenjuj kreativne i uzbudljive trenutke iz svakodnevnog života."
  // },
  // {
  //   icon: planet,
  //   title: "Persona+",
  //   body:
  //     "Zamisli… Novi, paralelni VR svet oko sebe, svet bez ograničenja sa pregršt mogucćnosti i izazova…"
  // },
  // {
  //   icon: planet,
  //   title: "Smart Nodes",
  //   body:
  //     "Pametne, unapredjene police. Praćenje detaljnih podataka prodaje pojedinacčnih artikala i kreiranje heat mapa nikada nije bilo brze i preciznije. Praćenje podataka u realnom vremenu širom planete – na jednom mestu."
  // },
];

import React from "react";
import Container from "../ui/Container";
import Title from "../ui/Title";
import Subtitle from "../ui/Subtitle";
import Flex, { FlexItem } from "styled-flex-component";
import portfolio from "../data/portfolio";
import Tabs from "../elements/Tabs";
import Reference from "./Reference";

const Portfolio = () => (
  <Container id="portfolio">
    <Flex column center style={{ paddingTop: "150px" }}>
      <FlexItem>
        <Title>Portfolio</Title>
      </FlexItem>
      <FlexItem>
        <Subtitle>Naš rad & Klijenti</Subtitle>
      </FlexItem>
      {/*<Tabs items={portfolio} />*/}
      <Reference />
    </Flex>
  </Container>
);

export default Portfolio;

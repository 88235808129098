import Title from "./Title";
import styled from "styled-components";
import theme from "../theme";

const QuotedText = styled(Title)`
  color: ${theme.color.red};
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 48px;
  @media screen and (max-width: 650px) {
    font-size: 32px;
  }
  &::before {
    content: '"';
    font-size: 64px;
  }
`;

export default QuotedText;

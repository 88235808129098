export default {
  font: {
    title: "Playfair Display",
    text: "Fira Sans"
  },
  lineHeight: {
    title: "77px",
    titleSm: "36px",
    subtitle: "64px",
    subtitleSm: "26px",
    text: "27px"
  },
  fontSize: {
    title: "72px",
    titleSm: "36px",
    subtitle: "48px",
    subtitleSm: "26px",
    text: "15px"
  },
  color: {
    black: "#000000",
    red: "#E74C3C",
    greyDark: "rgba(0, 0, 0, 0.7)",
    greyLight: "rgba(0, 0, 0, 0.4)",
    white: "#FFFFFF",
    whiteDark: "rgba(255, 255, 255, 0.4)"
  },
  shadow: "0px 14px 47px rgba(0, 0, 0, 0.11)",
  border: "1px solid rgba(0, 0, 0, 0.2)"
};

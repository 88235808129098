import styled from "styled-components";
import theme from "../theme";

const Title = styled.h1`
  font-weight: ${({ regular }) => (regular ? "normal" : "bold")};
  text-decoration: none;
  font-family: ${theme.font.title};
  font-size: ${({ small }) =>
    small ? theme.fontSize.subtitle : theme.fontSize.title};
  max-width: ${({ width }) => width};
  line-height: ${theme.lineHeight.title};
  margin: 0;
  color: ${({ white }) => (white ? theme.color.white : theme.color.black)};
  @media screen and (max-width: 650px) {
    font-size: ${({ small }) =>
      small ? theme.fontSize.subtitleSm : theme.fontSize.titleSm};
    line-height: ${theme.lineHeight.titleSm};
  }
`;

export default Title;

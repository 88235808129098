import styled from "styled-components";
import theme from "../theme";

const NavLink = styled.a`
  color: ${({ active }) => (active ? theme.color.red : theme.color.black)};
  font-weight: normal;
  text-decoration: none;
  padding: ${({ active }) => (active ? "30px 0" : "30px 0")};
  border-top: ${({ active }) =>
    active ? `2px solid ${theme.color.red}` : "none"};
  transition: color 0.5s ease;
  text-transform: ${({ capitalize }) =>
    !capitalize ? "uppercase" : "capitalize"};
  cursor: pointer;
  box-sizing: content-box;
  &:hover {
    color: ${theme.color.red};
    border-top: 2px solid ${theme.color.red};
  }
`;

export default NavLink;

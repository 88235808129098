import React from "react";
import Background from "../ui/Background";
import bg from "../assets/background.png";
import Container from "../ui/Container";
import { Grid, GridItem } from "styled-grid-component";
import styled from "styled-components";
import Title from "../ui/Title";
import Subtitle from "../ui/Subtitle";
import Text from "../ui/Text";
import QuotedText from "../ui/QuotedText";
import Padding from "../ui/Padding";

const ResponsiveGrid = styled(Grid)`
  @media screen and (max-width: 1300px) {
    grid-gap: 40px;
  }
  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
  }
`;

const About = () => {
  return (
    <Background src={bg} id="about">
      <Padding>
        <Container>
          <ResponsiveGrid
            width="100%"
            height="100%"
            templateColumns="1fr 1.5fr"
            gap="150px"
          >
            <GridItem>
              <Title small white>
                O Nama
              </Title>
              <Subtitle white style={{ marginBottom: "1em" }}>
                Par reči o našoj kompaniji
              </Subtitle>
              <ResponsiveGrid
                width="100%"
                height="100%"
                templateColumns="1.5fr 1fr"
                gap="40px"
              >
                <GridItem>
                  <Text white>
                    Veliki broj renomiranih klijenata i uspešno izvršenih poslova potvrda su naše posvećenosti i visokog kvaliteta naših usluga digitalizacije i razvoja softverskih rešenja.
                  </Text>
                </GridItem>
                <GridItem>
                  <Text white>
                    {" "}

                    Pružajući usluge internacionalnog konsaltinga i razvoja u sferi informacionih i komunikacionih tehnologija, obuhvatili smo najvažnije segmente modernog poslovanja u svetu.
                  </Text>
                </GridItem>
              </ResponsiveGrid>
            </GridItem>
            <GridItem>
              <QuotedText>
                Sa ponosom ističemo veliko iskustvo i ekspertizu našeg tima na polju informacionih tehnologija, upravljanja ljudskim resursima,finansija, energetike i maloprodajnog poslovanja.
              </QuotedText>
            </GridItem>
          </ResponsiveGrid>
        </Container>
      </Padding>
    </Background>
  );
};

export default About;

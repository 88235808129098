import face from "../assets/icons/outlined/face.svg";
import thumb from "../assets/icons/outlined/thumb.svg";
import verified from "../assets/icons/outlined/verified.svg";
import eye from "../assets/icons/outlined/eye.svg";

export default [
  {
    icon: face,
    title: "Poverljivost",
    body:
      "Sve informacije klijenta tretiramo kao poverljive i nikada ih ne koristimo u druge poslovne niti privatne svrhe, kako u toku samog rada tako i nakon zavrsenog projekta."
  },
  {
    icon: thumb,
    title: "Odgovornost",
    body:
      "Prihvatamo samo projekte za koje imamo neophodnu stručnost, iskustvo i kadrove koji mogu održati našu dokazanu reputaciju kompanije koja na efikasan način isporučuje isključivo usluge i proizvode najvišeg kvaliteta."
  },
  {
    icon: verified,
    title: "Profesionalnost",
    body:
      "Uvek održavamo potpuno profesionalan pristup u radu sa klijentima, na svim javnim obraćanjima i u saradnji sa partnerskim kompanijama."
  },
  {
    icon: eye,
    title: "Očekivanja",
    body:
      "Nikada ne ohrabrujemo i ne stvaramo nerealna očekivanja niti obećavamo nemoguće."
  }
];

import React, { Component } from "react";
import styled from "styled-components";
import { Formik } from "formik";
import Button from "../ui/Button";
import Input from "../ui/Input";
import { Grid, GridItem } from "styled-grid-component";
import * as yup from "yup";
import theme from "../theme";

const schema = yup.object().shape({
  fullName: yup.string("Name is not valid!").required("Name is required!"),
  email: yup
    .string()
    .email("E-mail is not valid!")
    .required("E-mail is required!"),
  message: yup.string("Message is not valid!").required("Message is required!")
});

const Form = styled(Formik)`
  margin-top: 75px;
  @media screen and (max-width: 600px) {
    margin-left: -30px;
    margin-right: -30px;
  }
`;

const FormField = styled(GridItem)`
  label {
    display: block;
    font-size: 0.85rem;
    color: ${theme.color.red};
    margin-top: 3px;
  }
`;

export default class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      fullName: "",
      email: "",
      message: ""
    };
    this.validate = this.validate.bind(this);
    this.submit = this.submit.bind(this);
  }
  async validate(values) {
    return await schema.isValid(values);
  }
  async submit(values, { setSubmitting, resetForm }) {
    try {
      setSubmitting(true);
      const res = await fetch("http://192.168.0.7:4000/send", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Forwarded-Host": window.location.hostname
        },
        body: JSON.stringify(values)
      });
      if (res.ok) {
        resetForm();
      }
      setSubmitting(false);
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  }

  render() {
    return (
      <Form
        initialValues={this.state}
        validate={this.validate}
        onSubmit={this.submit}
        validationSchema={schema}
      >
        {({
          errors,
          touched,
          isSubmitting,
          handleBlur,
          values,
          handleChange,
          handleSubmit
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              width="100%"
              height="100%"
              templateColumns="1fr"
              autoRows="minmax(85px, auto)"
            >
              <FormField>
                <Input
                  placeholder="Ime i prezime"
                  type="text"
                  name="fullName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fullName}
                />
                <label htmlFor="fullName">
                  {errors.fullName && touched.fullName && errors.fullName}
                </label>
              </FormField>
              <FormField>
                <Input
                  placeholder="E-mail"
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <label htmlFor="email">
                  {errors.email && touched.email && errors.email}
                </label>
              </FormField>
              <FormField>
                <Input
                  as="textarea"
                  placeholder="Dodatna poruka"
                  rows="10"
                  name="message"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                />
                <label htmlFor="message">
                  {errors.message && touched.message && errors.message}
                </label>
              </FormField>
              <GridItem>
                <Button
                  basic
                  type="submit"
                  disabled={isSubmitting}
                  style={{ marginTop: "1rem" }}
                >
                  Pošalji
                </Button>
              </GridItem>
            </Grid>
          </form>
        )}
      </Form>
    );
  }
}

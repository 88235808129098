import React, { Component } from "react";
import portfolio from "../data/portfolio";
import styled from "styled-components";
import services from "../data/services";
import theme from "../theme";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 100px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  min-height: 320px;
  //margin-bottom: 100px;
  cursor: pointer;
  padding: 20px;
  h3 {
    text-align: center;
    display: none;
    font-family: ${theme.font.title};
  }
  p {
    display: none;
    font-size: 14px;
    font-family: ${theme.font.text};
  }
  section {
    min-height: 120px;
  }
  img {
    filter: grayscale(100%);
    width: 200px;
    min-height: auto;
  }
  :hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    img {
      filter: none;
    }
    h3 {
      display: block;
    }
    p {
      display: block;
    }
  }
`;

class Reference extends Component {
  render() {
    return (
      <Container>
        {portfolio.map((item, i) => (
          <Item key={i}>
            <section>
              <img src={item.image} alt="" />
            </section>
            <h3>{item.title}</h3>
            <p>
              {item.servicesProvided.map((subItems, i) => (
                <p key={i}>- {subItems}</p>
              ))}
            </p>
          </Item>
        ))}
      </Container>
    );
  }
}

export default Reference;

import React from "react";
import Container from "../ui/Container";
import styled from "styled-components";
import theme from "../theme";

const FooterContainer = styled.footer`
  background: ${theme.color.black};
  color: ${theme.color.whiteDark};
  text-align: center;
  line-height: 27px;
  font-size: 15px;
  padding: 81px 0;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Container>
        IC&D <br />
        Copyright © IC&D 2022
      </Container>
    </FooterContainer>
  );
};

export default Footer;

import React, { Component } from "react";
import styled from "styled-components";
import theme from "../theme";
import Title from "./Title";
import Text from "./Text";

const PortfolioItemContainer = styled.div`
  padding: 50px;
  min-height: 300px;
  box-sizing: border-box;
  box-shadow: ${theme.shadow};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
  ul {
    text-align: left;
  }
  @media screen and (max-width: 900px) {
    padding: 0 25px;
    margin: 0 25px;
  }
`;

const PortfolioItemTitle = styled(Title)`
  font-size: 24px;
`;

const GrayscaleImage = styled.img`
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  filter: grayscale(100%) brightness(100%);
`;

export default class PortfolioItem extends Component {
  state = {
    active: false
  };
  render() {
    const { item } = this.props;
    if (this.state.active) {
      return (
        <PortfolioItemContainer
          onMouseLeave={() => this.setState({ active: false })}
        >
          <img
            src={item.image}
            alt="Portfolio"
            style={{ marginBottom: "1rem" }}
          />
          <div>
            <PortfolioItemTitle small>{item.title}</PortfolioItemTitle>
            <ul>
              {item.servicesProvided.map((service, i) => (
                <li key={i}>
                  <Text>- {service}</Text>
                </li>
              ))}
            </ul>
          </div>
        </PortfolioItemContainer>
      );
    } else {
      return (
        <GrayscaleImage
          src={item.image}
          onMouseEnter={() => this.setState({ active: true })}
          alt="Portfolio item"
        />
      );
    }
  }
}

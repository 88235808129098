import { createGlobalStyle } from "styled-components";
import "normalize.css";
import theme from "./theme";

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,700|Playfair+Display:400,700&subset=latin-ext');
html, body {
    max-width: 100%;
    overflow-x: hidden;
}
  body {
    font-family: ${theme.font.text};
    font-size: ${theme.fontSize.text};
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    -webkit-tap-highlight-color: transparent;
    ul {
      padding: 0;
      list-style: none;
    }
  }
`;

import NavLink from "./NavLink";
import styled from "styled-components";
import theme from "../theme";

const Tab = styled(NavLink)`
  border: none;
  border-bottom: ${({ active }) =>
    active ? `2px solid ${theme.color.red}` : "none"};
  background: none;
  box-sizing: border-box;
  outline: none;
  &:hover {
    border-bottom: 2px solid ${theme.color.red};
    border-top: none;
  }
`;

export default Tab;

import styled from "styled-components";
import theme from "../theme";

const Button = styled.button`
  text-decoration: none;
  color: ${theme.color.white};
  font-family: ${theme.font.text};
  text-transform: uppercase;
  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  font-style: normal;
  padding: ${({ basic }) => (basic ? "1rem 4rem" : "1rem 8rem 1rem 1rem")};
  background-color: ${theme.color.red};
  box-shadow: ${theme.shadow};
  border: none;
  letter-spacing: 2px;
  position: relative;
  /* z-index: -1; */
  cursor: pointer;
  outline: none;
  @media screen and (max-width: 650px) {
    font-size: 10px;
    line-height: 10px;
    padding: ${({ basic }) => (basic ? "1rem 4rem" : "1rem 7rem 1rem 1rem")};
  }
  ${({ basic }) => {
    if (!basic) {
      return `
      &::after {
    content: "▶";
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    top: 0;
    bottom: 0;
    width: 6rem;
    background-color: ${theme.color.black};
  }
      `;
    }
  }}
`;

export default Button;

import styled from "styled-components";

const Padding = styled.div`
  padding: 150px 0;
  @media screen and (max-width: 1237px) {
    padding: 100px 0;
  }
`;

export default Padding;

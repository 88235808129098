import styled from "styled-components";

const Container = styled.div`
  width: 80%;
  margin: 0 auto;
  height: ${({ screen }) => (screen ? "100vh" : "auto")};
  @media screen and (min-width: 576px) {
    max-width: 576px;
  }
  @media screen and (min-width: 768px) {
    max-width: 768px;
  }
  @media screen and (min-width: 992px) {
    max-width: 992px;
  }
  @media screen and (min-width: 1200px) {
    max-width: 1500px;
  }
`;

export default Container;

import React from "react";
import Title from "../ui/Title";
import Subtitle from "../ui/Subtitle";
import Container from "../ui/Container";
import services from "../data/services";
import { Grid, GridItem } from "styled-grid-component";
import Card from "../elements/Card";
import styled from "styled-components";
import Rectangle from "../ui/Rectangle";
import Padding from "../ui/Padding";

const ResponsiveGrid = styled(Grid)`
  @media screen and (max-width: 1300px) {
    grid-gap: 40px;
  }
  @media screen and (max-width: 1150px) {
    grid-template-columns: 1fr;
  }
`;

const Section = styled.section`
  position: relative;
  margin-top: 0;
  @media screen and (max-width: 650px) {
    margin-top: -22vh;
  }
  @media screen and (orientation: landscape) and (max-width: 800px) {
    margin-top: 30vh;
  }
`;

const Services = () => {
  return (
    <Section id="services">
      <Rectangle right="0" top="20%" offset={40} scale=".8" />
      <Padding>
        <Container>
          <div style={{ marginBottom: "125px" }}>
            <Title small>Naše Usluge</Title>
            <Subtitle>
              Kvalitet je uvek na <br /> prvom mestu.
            </Subtitle>
          </div>
          <ResponsiveGrid
            width="100%"
            height="100%"
            templateColumns="repeat(3, 1fr)"
            gap="83px"
          >
            {services.map(({ icon, title, body }) => (
              <GridItem key={title}>
                <Card icon={icon} title={title} body={body} />
              </GridItem>
            ))}
          </ResponsiveGrid>
        </Container>
      </Padding>
    </Section>
  );
};

export default Services;

import styled from "styled-components";

const Input = styled.input`
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 1.25rem;
  color: rgba(0, 0, 0, 0.9);
  width: 80%;
  outline: none;
  margin: 0 auto;
`;

export default Input;

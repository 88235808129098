import React from "react";
import rectangle from "../assets/rectangle.svg";
import styled from "styled-components";

const Image = styled.img`
  position: absolute;
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  z-index: -1;
  transform: ${({ offset, scale }) =>
    `translateX(${offset}%) scale(${scale ? scale : 1})`};
  opacity: 0.9;
  @media screen and (max-width: 650px) {
    transform: ${({ offset, scale }) =>
      `translateX(${offset * 1.5}%) scale(${scale ? scale : 1})`};
  }
`;

const Rectangle = props => {
  return <Image src={rectangle} alt="Rectangle" {...props} />;
};

export default Rectangle;

import styled from "styled-components";
import theme from "../theme";

const Background = styled.div`
  background-color: black;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  border-top: 2px solid ${theme.color.red};
  z-index: -2;
`;

export default Background;

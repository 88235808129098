import React, { Component } from "react";
import Header from "./sections/Header";
import Services from "./sections/Services";
import Approach from "./sections/Approach";
import Portfolio from "./sections/Portfolio";
import Solutions from "./sections/Solutions";
import About from "./sections/About";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import Loader from "./elements/Loader";

class App extends Component {
  constructor() {
    super();
    this.state = {
      videoReady: false
    };
  }
  render() {
    return (
      <>
        {!this.state.videoReady && <Loader />}
        <Header onVideoReady={() => this.setState({ videoReady: true })} />
        <Services />
        <Approach />
        <Portfolio />
        <Solutions />
        <About />
        <Contact />
        <Footer />
      </>
    );
  }
}

export default App;

import styled from "styled-components";
import theme from "../theme";

const Subtitle = styled.h1`
  font-weight: normal;
  font-family: ${theme.font.title};
  font-size: ${theme.fontSize.subtitle};
  width: ${({ width }) => width};
  line-height: ${theme.lineHeight.subtitle};
  margin: 0;
  color: ${theme.color.greyLight};
  color: ${({ white }) =>
    white ? theme.color.whiteDark : theme.color.greyLight};
  @media screen and (max-width: 650px) {
    font-size: ${theme.fontSize.subtitleSm};
    line-height: ${theme.lineHeight.subtitleSm};
  }
`;

export default Subtitle;
